@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom styles below */
@layer base {
  h1 {
    @apply text-2xl;
    @apply font-kenoky;
    @apply font-light;
    @apply leading-160;
  }
  h2 {
    @apply text-xl;
    @apply leading-160;
  }
  p {
    @apply font-spartan;
    @apply text-not-black;
    @apply leading-160;
  }
  li {
    @apply font-spartan;
    @apply text-not-black;
    @apply leading-8;
  }

  .field-label {
    @apply block text-sm font-semibold text-not-black;
  }
  .form-textarea {
    @apply block h-64 w-full bg-gray-100 border border-white text-sm text-not-black overflow-y-auto;
  }
}
